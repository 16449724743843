import React from "react";
import { useState, useEffect, useReducer } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from "react-router";

const UpdateTeacher =()=>{

    const [user,setuser] = useState({
        Name:"",
        pass:"",
        email:"",
        repassword:"",
        Ph:"",
        Subject:"",
        Address:"",
      });
    
    const [selectValue, setSelectValue] = useState({
      ClassTeacher: "None"
    });

    const { id } = useParams();


    useEffect(() => {

        const url = 'http://localhost:5000/api/teacher?_id='+id;
       
        showContent(url);   
    }, []);


    const showContent = async (url) => {

        const res = await fetch(url);
        const data = await res.json();   
        const  obj =data.Obj_data[0];  

        console.log(obj);
       
        
       
    
        setSelectValue({...selectValue, ClassTeacher: obj.ClassTeacher });       
        setuser({...user,  Name:obj.Name,  password:obj.pass,  email:obj.email, Address:obj.Address,Ph:obj.Ph, ClassTeacher:obj.ClassTeacher, Subject:obj.Subject});
        
        return obj;  
      }


      const handleTextboxChange = (event) => {
        console.log(event.target.value);
        setuser(event.target.value);
    };

    const handleSelectChange = (event) => {
        const { name, value } = event.target

        setSelectValue({
            ...selectValue,
            [name]: value
        });
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        const url = "http://localhost:5000/api/teacher/update/" + id;
       
        SubmitData(url);
    }

    const SubmitData = async (url) => {

        const logData = {
            ClassTeacher: selectValue.ClassTeacher,
            Name:user.name,
            pass:user.pass,
            email:user.email,    
            Ph:user.Ph,    
            Subject:user.Subject,
            Address:user.Address,    
          };
      


        
    
        try {
          const data = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(logData)
        });

        
       // console.log();
        // const restatus = await data.json();
        
    
          if (data.status === 200) {
            toast.success("Student Updation Successfull", {
                position: "top-right",
                theme: "colored",
            });
        }
        else {
            toast.error("Student Updation Failed", {
                position: "top-right",
                theme: "colored",
            });
        }
    
         
        }
        catch (e) {
          console.log(e);
        }
    
      }



    return (
        <main>
        <div className="container">
        {
          <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
         Registration sucessfull
          <button type="button" className="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        }
    
          <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
    
                  <div className="d-flex justify-content-center py-4">
                    <a href="index.html" className="logo d-flex align-items-center w-auto">
                      <img src="assets/img/logo.png" alt=""/>
                      <span className="d-none d-lg-block"> Teacher Registration</span>
                    </a>
                  </div>
    
                  <div className="card mb-3">
    
                    <div className="card-body">
    
                      <div className="pt-4 pb-2">
                        <h5 className="card-title text-center pb-0 fs-4">Create an Account</h5>
                        <p className="text-center small">Enter your personal details to create account</p>
                      </div>
    
                      <form className="row g-3 needs-validation" novalidate>
                         <div className="col-6">
                        <div className="col-12">
                          <label for="yourName" className="form-label">Your Name</label>
                          <input type="text" name="Name" className="form-control" value={user.Name} onChange={e => setuser({...user, Name : e.target.value})}  required/>
                          <div className="invalid-feedback">Please, enter your name!</div>
                        </div>
                        <div className="col-12">
                          <label for="Ph" className="form-label">Phone Number</label>
                          <input type="text" name="Ph" className="form-control" value={user.Ph} onChange={e => setuser({...user, Ph : e.target.value})}  required/>
                          <div className="invalid-feedback">Please enter your Phone Number </div>
                        </div>
    
                        <div className="col-12">
                          <label for="yourEmail" className="form-label">Your Email</label>
                          <input type="email" name="email" className="form-control" value={user.email} onChange={e => setuser({...user, Email : e.target.value})}  required/>
                          <div className="invalid-feedback">Please enter a valid Email adddress!</div>
                        </div>
                         <div className="col-12">
                          <label for="fatherName" className="form-label">Father Name</label>
                          <input type="text" name="Subject" className="form-control" value={user.Subject} onChange={e => setuser({...user, Subject : e.target.value})}  required/>
                          <div className="invalid-feedback">Please enter your Father Name</div>
                        </div>
    
                       
                        </div>
                        <div className="col-6">
                                  <div className="col-12">
                                    <label for="Class" className="form-label">Class with section</label>
                                   {/* <input type="text" name="name" className="form-control" value={user.name} onChange={handleChange} required/> 
                                    <div className="invalid-feedback">Please, enter your Class ith section</div> */}

                                        <select className="form-select" id="Class" aria-label="State" name="ClassTeacher" value={selectValue.ClassTeacher} onChange={handleSelectChange}>
                                        <option selected="" value="None">None</option>
                                            <option value="Preschool">Preschool</option>
                                            <option value="1A">1A</option>
                                            <option value="1B">1B</option>
                                            <option value="2A">2A</option>
                                            <option value="2B">2B</option>
                                            <option value="3A">3A</option>
                                            <option value="3B">3B</option>
                                            <option value="4A">4A</option>
                                            <option value="4B">4B</option>
                                            <option value="5A">5A</option>
                                            <option value="5B">5B</option>
                                            <option value="6A">6A</option>
                                            <option value="6B">6B</option>
                                            <option value="7A">7A</option>
                                            <option value="7B">7B</option>
                                            <option value="8A">8A</option>
                                            <option value="8B">8B</option>
                                            <option value="9A">9A</option>
                                            <option value="9B">9B</option>

                                        </select>


                                  </div>
              
                                  <div className="col-12">
                                    <label for="Address" className="form-label">Address</label>
                                    <input type="text" name="Address" className="form-control" value={user.Address} onChange={e => setuser({...user, Address : e.target.value})}  required/>
                                    <div className="invalid-feedback">Please enter Address</div>
                                  </div>
                                  <div className="col-12">
                                    <label for="yourPassword" className="form-label">Password</label>
                                    <input type="text" name="password" className="form-control" value={user.password} onChange={e => setuser({...user, password : e.target.value})}  required/>
                                    <div className="invalid-feedback">Please enter your password!</div>
                                  </div>
              
                                  <div className="col-12">
                                    <label for="yourPassword" className="form-label">Re Enter Password</label>
                                    <input type="text" name="password" className="form-control" value={user.password}  onChange={e => setuser({...user, password : e.target.value})}required/>
                                    <div className="invalid-feedback">Please Re enter your password!</div>
                                  </div>
                          </div>

    
                     {/*    <div className="col-12">
                          <div className="form-check">
                            <input className="form-check-input" name="terms" type="checkbox" value="" id="acceptTerms" required/>
                            <label className="form-check-label" for="acceptTerms">I agree and accept the <a href="#">terms and conditions</a></label>
                            <div className="invalid-feedback">You must agree before submitting.</div>
                          </div>
                        </div> */}
                        <div className="col-12">
                          <button className="btn btn-primary w-100" type="submit"  on onClick={handleSubmit}>Update Student</button>
                        </div>
                        <div className="col-12">
                          <p className="small mb-0">Already have an account? <a href="Login">Log in</a></p>
                        </div>
                       
                       
                      </form>
    
                    </div>
                  </div>
    
                 
    
                </div>
              </div>
            </div>
    
          </section>
    
        </div>
        <ToastContainer />
      </main>
    );
}
export default UpdateTeacher;