import  React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";


const Dashboard = () => {

    const navigate = useNavigate();
   // const [authenticated, setauthenticated] = useState(false);


    const Stumobile = localStorage.getItem("mobile");
 
    const [stu,setstu]= useState('');
    const [stufee,setstufee]= useState('');
    const [checkedItems, setCheckedItems] = useState({});

    const url = 'http://localhost:5000/api/student?Ph='+Stumobile;
   

    useEffect(() => {   
         moveto();  
         showContent(url);   
         
    }, []);

    const moveto =()=>{
        const loggedInUser = localStorage.getItem("authenticated");
        if(!loggedInUser)
        {
            navigate("Login");
        }
    }
    
   const showFee = async(feeurl)=>{

    const res = await fetch(feeurl);
    const data = await res.json();  
    setstufee(data.Obj_data); 

   }

    const showContent = async (url) => {

        const res = await fetch(url);
        const data = await res.json();       
        setstu(data.Stu_data);

        const stuclass= data.Stu_data[0].Class;
        const characters = stuclass.split('');
      
        const feeurl = 'http://localhost:5000/api/Feestr?Class='+ characters[0] ;
        showFee(feeurl);  

      }

      const handleCheckboxChange = (itemId) => {
        setCheckedItems((prevItems) => ({
          ...prevItems,
          [itemId]: !prevItems[itemId],
        }));
       // console.log(checkedItems);
      };
    

    
        return (
            <main id="main" class="main">

                <div class="pagetitle">
                    <h1>Dashboard</h1>
                    <nav>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li class="breadcrumb-item active">Dashboard</li>
                        </ol>
                    </nav>
                </div>

                <section class="section dashboard">
                < div className="row col-lg-12 col-md-12 ">
          <div className="row col-lg-12 col-md-12 ">


            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Student Data</h5>


                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Address</th>
                      <th scope="col">Mobile</th>
                      <th scope="col">Class</th>
                      <th scope="col">Class Teacher</th>
                      <th scope="col">Father</th>
                      <th scope="col">Mother</th>
                      <th scope="col">Edit</th>
                     
                    </tr>
                  </thead>
                  <tbody>
                     {

                      stu && stu.map((stu, i) => (
                        <tr key={stu._id}>
                     
                          <td>{stu.Name} </td>
                          <td>{stu.Email}</td>
                          <td>{stu.Address} </td>
                          <td>{stu.Ph}</td>
                          
                          <td>{stu.Class} </td>
                          <td>{stu.ClassTeacher}</td>
                          <td>{stu.FatherName} </td>
                          <td>{stu.Mothername}</td>

                          <td><button class="btn btn-primary w-100" onClick={() => { }} type="submit">Edit</button></td>
                        </tr>
                      ))}
 
                  </tbody>
                </table>

              </div>
            </div>
          </div>
          </div>


          {/* Fee Data--------------------*/}


          < div className="row col-lg-6 col-md-8 ">
        

          <div className="card">
              <div className="card-body">
                <h5 className="card-title">Student Fee Data</h5>


                <table className="table table-hover">
                  <thead>
                    <tr>
                      
                      <th scope="col">Select Fee Month</th>
                      <th scope="col">#</th>
                      <th scope="col">Month</th>
                      <th scope="col">Amount</th>
                    {/*   <th scope="col">Date</th>
                      <th scope="col">Transection ID</th>  
                      <th scope="col">Pay</th>    */}                
                     
                    </tr>
                  </thead>
                  <tbody>
                  {   

                  stufee && stufee.map((fee, i) => (
                        <tr key={fee._id}>
                          <th>
                          <input type="checkbox"  id={fee._id} checked={checkedItems[fee._id] || false}  onChange={() => handleCheckboxChange(fee._id)}
      />
                          </th>
                          <th scope="row">{i + 1}</th>                 
                          <td>{fee.Month}</td>
                          <td>{fee.Amount}</td>
                         {/*  <td><button class="btn btn-primary w-100" type="submit">Pay {fee.Amount}</button></td> */}
                        </tr>
                      ))}
 
                  </tbody>
                </table>

                <button class="btn btn-primary w-100" type="submit">Pay</button>

              </div>
            </div>
            </div>
            

                </section>
            </main>
        );
    }


export default Dashboard;