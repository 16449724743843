import React from "react";
import { useState, useEffect, useReducer } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Enquiry = () => {

    const [content, setContent] = useState();

    useEffect(() => {
        const url = 'http://localhost:5000/api/enquiry';
        showContent(url);
    }, []);

    const showContent = async (url) => {

        const res = await fetch(url);
        const data = await res.json();       
        setContent(data.content_data);
    }

    const deleteData = async (id) => {

        const url = "http://localhost:5000/api/content/delete/" + id;

        const res = await fetch(url, {
            method: "delete"
        })
        const data = await res;
        if (data.status == "200") {
            toast.success("Data Deleted Successfull", {
                position: "top-right",
                theme: "colored",
            });
        }
        else {
            toast.error("Process Failed", {
                position: "top-right",
                theme: "colored",
            });
        }
    }

    return (
        <main id="main" class="main">

            <div class="pagetitle">
                <h1>Content Tables</h1>
                <nav>
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li class="breadcrumb-item">Content Table</li>

                    </ol>
                </nav>
            </div>

            <section class="section">

                <div class="row">
                    <div className="row col-lg-12 col-md-12 ">


                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">Content Data</h5>


                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Phone Num</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Enquiry</th>
                                            <th scope="col">Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {content && content.map((content_data, i) => (
                                            <tr key={content_data._id}>
                                                <th scope="row">{i + 1}</th>
                                                <td>{content_data.name}</td>
                                                <td>{content_data.ph}</td>
                                                <td>{content_data.email}</td>
                                                <td>{content_data.enqText}</td>
                                                <td>{content_data.addDate}</td>
                                                {/* <td><button class="btn btn-primary w-100" onClick={() => { deleteData(content_data._id) }} type="submit">Delete</button></td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </main>
    );
}

export default Enquiry;