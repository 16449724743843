import React, {useState} from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TeacherReg =()=>{

  const [user,setuser] = useState({
    Name:"",
    pass:"",
    email:"",
    repassword:"",
    Ph:"",
    Subject:"",
    Address:"",
  });

  const [selectValue, setSelectValue] = useState({
    ClassTeacher: "None" //Default Value   
});

  const handleChange=(e)=>{
    const {name,value}= e.target
    setuser ({
      ...user,
      [name]:value
    })
  }

  const handleSelectChange = (event) => {
    const { name, value } = event.target

    setSelectValue({
        ...selectValue,
        [name]: value
    });
};


  const callApi = async (url) => {

    const logData = {
      ClassTeacher: selectValue.ClassTeacher,
      Name:user.Name,
      pass:user.pass,
      email:user.email,    
      Ph:user.Ph,    
      Subject:user.Subject,
      Address:user.Address,    
    };

    try {
      const data = await fetch(url, {
        method: "post",
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(logData)
      });
    
     const restatus = await data.json();
      console.log(restatus);

      if (restatus.Status === 200) {
        toast.success("Teacher Registration Successfull", {
            position: "top-right",
            theme: "colored",
        });
    }
    else {
        toast.error( restatus.mes + " Registration Failed ", {
            position: "top-right",
            theme: "colored",
        });
    }

     
    }
    catch (e) {
      console.log(e);
    }

  }

  const [res, setRes] = useState(false);


  const handleSubmit = (e) => {
    e.preventDefault()

    if (user.pass == user.repassword) {
      callApi("http://localhost:5000/api/teacher/add");  
    }
    else {  setRes(false);

      toast.error("Password does not Match", {
        position: "top-right",
        theme: "colored",
       
    });
    }

  };


    return (
        <main>
        <div className="container">
        {res &&
          <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
         Registration sucessfull
          <button type="button" className="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        }
    
          <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
    
                  <div className="d-flex justify-content-center py-4">
                    <a href="index.html" className="logo d-flex align-items-center w-auto">
                      <img src="assets/img/logo.png" alt=""/>
                      <span className="d-none d-lg-block"> Teacher Registration</span>
                    </a>
                  </div>
    
                  <div className="card mb-3">
    
                    <div className="card-body">
    
                      <div className="pt-4 pb-2">
                        <h5 className="card-title text-center pb-0 fs-4">Create an Account</h5>
                        <p className="text-center small">Enter Teacher's personal details to create account</p>
                      </div>
    
                      <form className="row g-3 needs-validation" novalidate>
                         <div className="col-6">
                        <div className="col-12">
                          <label for="yourName" className="form-label">Your Name</label>
                          <input type="text" name="Name" className="form-control" value={user.Name} onChange={handleChange} required/>
                          <div className="invalid-feedback">Please, enter your name!</div>
                        </div>
                        <div className="col-12">
                          <label for="Ph" className="form-label">Phone Number</label>
                          <input type="text" name="Ph" className="form-control" value={user.Ph} onChange={handleChange} required/>
                          <div className="invalid-feedback">Please enter your Phone Number </div>
                        </div>
    
                        <div className="col-12">
                          <label for="yourEmail" className="form-label">Your Email</label>
                          <input type="email" name="email" className="form-control" value={user.email} onChange={handleChange} required/>
                          <div className="invalid-feedback">Please enter a valid Email adddress!</div>
                        </div>
                         <div className="col-12">
                          <label for="Subject" className="form-label">Subject</label>
                          <input type="text" name="Subject" className="form-control" value={user.Subject} onChange={handleChange} required/>
                          <div className="invalid-feedback">Please enter your Subject</div>
                        </div>
    
                      
                        </div>
                        <div className="col-6">
                                  <div className="col-12">
                                    <label for="Class" className="form-label">ClassTeacher</label>
                                   {/* <input type="text" name="name" className="form-control" value={user.name} onChange={handleChange} required/> 
                                    <div className="invalid-feedback">Please, enter your Class ith section</div> */}

                                        <select className="form-select" id="Class" aria-label="State" name="ClassTeacher" value={selectValue.ClassTeacher} onChange={handleSelectChange}>
                                            <option selected="" value="None">None</option>
                                            <option value="Preschool">Preschool</option>
                                            <option value="1A">1A</option>
                                            <option value="1B">1B</option>
                                            <option value="2A">2A</option>
                                            <option value="2B">2B</option>
                                            <option value="3A">3A</option>
                                            <option value="3B">3B</option>
                                            <option value="4A">4A</option>
                                            <option value="4B">4B</option>
                                            <option value="5A">5A</option>
                                            <option value="5B">5B</option>
                                            <option value="6A">6A</option>
                                            <option value="6B">6B</option>
                                            <option value="7A">7A</option>
                                            <option value="7B">7B</option>
                                            <option value="8A">8A</option>
                                            <option value="8B">8B</option>
                                            <option value="9A">9A</option>
                                            <option value="9B">9B</option>

                                        </select>


                                  </div>

                                  <div className="col-12">
                                    <label for="Address" className="form-label">Address</label>
                                    <input type="text" name="Address" className="form-control" value={user.Address} onChange={handleChange} required/>
                                    <div className="invalid-feedback">Please enter your Address</div>
                                    </div>
              
                                 {/*  <div className="col-12">
                                    <label for="ClassTeacher" className="form-label">Class Teacher</label>
                                    <input type="text" name="ClassTeacher" className="form-control" value={user.ClassTeacher} onChange={handleChange} required/>
                                    <div className="invalid-feedback">Please enter Class Teacher name</div>
                                  </div> */}
                                  <div className="col-12">
                                    <label for="yourPassword" className="form-label">Password</label>
                                    <input type="password" name="pass" className="form-control" value={user.pass} onChange={handleChange} required/>
                                    <div className="invalid-feedback">Please enter your password!</div>
                                  </div>
              
                                  <div className="col-12">
                                    <label for="yourPassword" className="form-label">Re Enter Password</label>
                                    <input type="password" name="repassword" className="form-control" value={user.repassword} onChange={handleChange} required/>
                                    <div className="invalid-feedback">Please Re enter your password!</div>
                                  </div>
                          </div>

    
                     {/*    <div className="col-12">
                          <div className="form-check">
                            <input className="form-check-input" name="terms" type="checkbox" value="" id="acceptTerms" required/>
                            <label className="form-check-label" for="acceptTerms">I agree and accept the <a href="#">terms and conditions</a></label>
                            <div className="invalid-feedback">You must agree before submitting.</div>
                          </div>
                        </div> */}
                        <div className="col-12">
                          <button className="btn btn-primary w-100" type="submit"  on onClick={handleSubmit}>Create Account</button>
                        </div>
                        <div className="col-12">
                          <p className="small mb-0">Already have an account? <a href="Login">Log in</a></p>
                        </div>
                       
                       
                      </form>
    
                    </div>
                  </div>
    
                 
    
                </div>
              </div>
            </div>
    
          </section>
    
        </div>
        <ToastContainer />
      </main>
    );
}

export  default TeacherReg;