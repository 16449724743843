import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';


const Login = () => {


  const navigate = useNavigate();
  const [user, setuser] = useState({
    password: "",
    ph: ""
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setuser({
      ...user,
      [name]: value
    })
  }

  const callApi = async (url) => {

    const logData = {
      Ph: user.ph,
      pass: user.password
    };

    try {
      const data = await fetch(url, {
        method: "post",
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(logData)
      });
      const restatus = await data.json();

      if (restatus.resp === 1) {
        setauthenticated(true)
        localStorage.setItem("authenticated", true);   
        localStorage.setItem("mobile", user.ph);   
       
     
      
        toast.success("Student Login Successfull", {
          position: "top-right",
          theme: "colored",
          
      });
    setTimeout( ()=>{navigate("/")},2000);
      }
      else
      {     
     
          toast.error("Student Login Failed", {
            position: "top-right",
            theme: "colored",
        });
        }    
      return restatus;
    }
    catch (e) {
      console.log(e);
    }

  }

  const [authenticated, setauthenticated] = useState(localStorage.getItem(localStorage.getItem("authenticated") || false));

  const handleSubmit = (e) => {
    e.preventDefault()

    callApi("http://localhost:5000/api/student/login");

  
  };

  return (
    <main>
      <div class="container">

        <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                <div class="d-flex justify-content-center py-4">
                  <a href="index.html" class="logo d-flex align-items-center w-auto">
                    <img src="assets/img/logo.png" alt="" />
                    <span class="d-none d-lg-block">LOGIN</span>
                  </a>
                </div>

                <div class="card mb-3">

                  <div class="card-body">

                    <div class="pt-4 pb-2">
                      <h5 class="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                      <p class="text-center small">Enter your username & password to login</p>
                    </div>

                    <form class="row g-3 needs-validation" novalidate>

                      <div class="col-12">
                        <label for="yourPh" class="form-label">Your Phone Number</label>
                        <input type="email" name="ph" class="form-control" value={user.ph} onChange={handleChange} required />
                        <div class="invalid-feedback">Please enter a valid Email adddress!</div>
                      </div>

                      <div class="col-12">
                        <label for="yourPassword" class="form-label">Password</label>
                        <input type="password" name="password" class="form-control" value={user.password} onChange={handleChange} required />
                        <div class="invalid-feedback">Please enter your password!</div>
                      </div>

                      <div class="col-12">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" name="remember" value="true" id="rememberMe" />
                          <label class="form-check-label" for="rememberMe">Remember me</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <button class="btn btn-primary w-100" type="submit" onClick={handleSubmit}>Login</button>
                      </div>
                      <div class="col-12">
                        <p class="small mb-0">Don't have account? <a href="Register">Create an account</a></p>
                      </div>
                    </form>

                  </div>
                </div>

                <div class="credits">

                </div>

              </div>
            </div>
          </div>

        </section>

      </div>
      <ToastContainer />
    </main>
  );
}

export default Login;