import  React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const StudentPage = ()=>{
    const navigate = useNavigate();
    
    const [stu,setstu]= useState('');
    const StuClass='2A';


    const url = 'http://localhost:5000/api/student';

    useEffect(() => {   
        moveto();  
        GetStuData(url);
       
        
   }, []);

    const moveto =()=>{
        const loggedInUser = localStorage.getItem("authenticated");
        if(!loggedInUser)
        {
            navigate("Login");
        }
    }

    const  GetStuData = async(url)=>{

        const data= await fetch(url);
        const res= await data.json();
       

        setstu(res.Stu_data);

    }


    
    
    return (
        <main id="main" class="main">

        <div class="pagetitle">
            <h1>Dashboard</h1>
            <nav>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                    <li class="breadcrumb-item active">Dashboard</li>
                </ol>
            </nav>
        </div>

        <section class="section dashboard">
        < div className="row col-lg-12 col-md-12 ">
  <div className="row col-lg-12 col-md-12 ">


    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Student Data</h5>


        <table className="table table-hover">
          <thead>
            <tr>
            <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Address</th>
              <th scope="col">Mobile</th>
              <th scope="col">Class</th>
              <th scope="col">Class Teacher</th>
              <th scope="col">Father</th>
              <th scope="col">Mother</th>
              <th scope="col">Edit</th>
             
            </tr>
          </thead>
          <tbody>
             {

              stu && stu.map((stu, i) => (
                <tr key={stu._id}>
             
             <th scope="row">{i + 1}</th>   
                  <td>{stu.Name} </td>
                  <td>{stu.Email}</td>
                  <td>{stu.Address} </td>
                  <td>{stu.Ph}</td>
                  
                  <td>{stu.Class} </td>
                  <td>{stu.ClassTeacher}</td>
                  <td>{stu.FatherName} </td>
                  <td>{stu.Mothername}</td>

                  <td><Link class="btn btn-primary w-100" to={`/UpdateStudent/${stu._id}`} >Update</Link></td>
                </tr>
              ))}

          </tbody>
        </table>

      </div>
    </div>
  </div>
  </div>           

  </section>
</main>
);
}

export default StudentPage;