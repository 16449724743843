import React from "react";
import { useState, useEffect, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TeacherPage = ()=>{
    const navigate = useNavigate();
    
    const [stu,setstu]= useState('');
    const [comUpdate, forceUpdate] = useReducer((x) => x + 1, 0);
    


    const url = "http://localhost:5000/api/teacher";

    useEffect(() => {   
        moveto();  
        GetData(url);
       // forceUpdate();
       
        
   }, []);

    const moveto =()=>{
        const loggedInUser = localStorage.getItem("authenticated");
        if(!loggedInUser)
        {
            navigate("Login");
        }
    }

    const  GetData = async(url)=>{

        const data= await fetch(url);
        const res= await data.json(); 
         setstu(res.Obj_data);
    }

    const deleteData = async (id) => {

        const delurl = "http://localhost:5000/api/teacher/delete/" + id;

        console.log(delurl);
    
        const res = await fetch(delurl, {
          method: "delete"
        })           

        //const data = res;

        console.log(res.status);
    
        if (res.status == 200) {
          toast.success("Data Deleted Successfull", {
            position: "top-right",
            theme: "colored",
          });
        }
        else {
          toast.error("Process Failed", {
            position: "top-right",
            theme: "colored",
          });
        }
        GetData(url);
      }


    
    
    return (
        <main id="main" class="main">

        <div class="pagetitle">
            <h1>Dashboard</h1>
            <nav>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                    <li class="breadcrumb-item active">Dashboard</li>
                </ol>
            </nav>
        </div>

        <section class="section dashboard">
        < div className="row col-lg-12 col-md-12 ">
  <div className="row col-lg-12 col-md-12 ">


    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Teacher Data</h5>


        <table className="table table-hover">
          <thead>
            <tr>
            <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>            
              <th scope="col">Mobile</th>              
              <th scope="col">Subject</th>
              <th scope="col">Class Teacher</th> 
               <th scope="col">Address</th>
              <th scope="col">Joindate</th>
              <th scope="col">Isvarified</th>
              <th scope="col">Edit</th>
             
            </tr>
          </thead>
          <tbody>
             {
              
              stu && stu.map((stu, i) => (
                <tr key={stu._id}>
             
             <th scope="row">{i + 1}</th>   
                  <td>{stu.Name} </td>
                  <td>{stu.email}</td>                 
                  <td>{stu.Ph}</td>                  
                  <td>{stu.Subject} </td>
                  <td>{stu.ClassTeacher}</td> 
                  <td>{stu.Address} </td>
                  <td>{stu.Joindate.split("T")[0]} </td>
                  <td>{(stu.Isvarified)}</td>

                  <td><Link class="btn btn-primary w-100" to={`/UpdateTeacher/${stu._id}`} >Update</Link></td>
                  <td><Link class="btn btn-primary w-100" onClick={() => { deleteData(stu._id) }} >Delete</Link></td>
                </tr>
              ))}

          </tbody>
        </table>

      </div>
    </div>
  </div>
  </div>           

  </section>
  <ToastContainer />
</main>
);
}

export default TeacherPage;