import './App.css';
import Header from './component/Header';
import Sidebar from './component/Sidebar';
import Footer from './component/Footer';
import Login from './component/Login';
import Register from './component/Register';
import Slider from './component/slider';
import Content from './component/content';
import Dashboard from './component/Dashboard';
import Addcontent from './component/AddContent';
import UpdateContent from './component/UpdateContent';
import FeeAdmin from './component/FeeAdmin';
import Enquiry from './component/Enq';
import TeacherReg from './component/TeacherReg';
import StudentPage from './component/StudentPage';
import TeacherPage from './component/TeacherPage';
import UpdateStudent from './component/UpdateStudent';
import UpdateTeacher from './component/UpdateTeacher';
import { Route, Routes } from 'react-router-dom';

function App() {

  return (
    <div>
      <Header />
      <Sidebar />
      <Routes>
        <Route path='Login' element={<Login />}></Route>
        <Route path='Register' element={<Register />}></Route>
        <Route path='Slider' element={<Slider />}></Route>
        <Route path='Content' element={<Content />}></Route>
        <Route path='/' element={<Dashboard />}></Route>
        <Route path='Addcontent' element={<Addcontent/>}></Route>
        <Route path='UpdateContent/:id' element={<UpdateContent/>}></Route>
        <Route path ="FeeAdmin" element={<FeeAdmin/>}></Route>
        <Route path ="Enquiry" element={<Enquiry/>}></Route>
        <Route path ="TeacherReg" element={<TeacherReg/>}></Route>
        <Route path ="StudentPage" element={<StudentPage/>}></Route>
        <Route path ="TeacherPage" element={<TeacherPage/>}></Route>
        <Route path ="UpdateStudent/:id" element={<UpdateStudent/>}></Route>
        <Route path ="UpdateTeacher/:id" element={<UpdateTeacher/>}></Route>
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
