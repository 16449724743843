import React from "react";

import Addcontent from "./AddContent";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect, useReducer } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Content = () => {

  const [content, setContent] = useState();
  const [comUpdate, forceUpdate] = useReducer((x) => x + 1, 0);
 

  useEffect(() => {
    moveto();
    const url = 'http://localhost:5000/api/content';
    showContent(url);
  }, [comUpdate]);


  const navigate = useNavigate();


   const moveto =()=>{
       const loggedInUser = localStorage.getItem("authenticated");
       if(!loggedInUser)
       {
           navigate("/Login");
       }
   }

  const showContent = async (url) => {

    const res = await fetch(url);
    const data = await res.json();
    //  console.log(data.content_data);
    setContent(data.content_data);
  }

  const deleteData = async (id) => {

    const url = "http://localhost:5000/api/content/delete/" + id;

    const res = await fetch(url, {
      method: "delete"
    })
    const data = await res;
    if (data.status == "200") {
      toast.success("Data Deleted Successfull", {
        position: "top-right",
        theme: "colored",
      });
    }
    else {
      toast.error("Process Failed", {
        position: "top-right",
        theme: "colored",
      });
    }
    forceUpdate()
  }

 // let navigate = useNavigate();
  const routeChange = () => {
    let path = `/Addcontent`;
    navigate(path);
  }

  return (
    <main id="main" class="main">
     
      <div class="pagetitle">
        <h1>Content Tables</h1>
        <nav>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
            <li class="breadcrumb-item">Content Table</li>

          </ol>
        </nav>
      </div>

      <section class="section">

        <div class="row">
          <div className="row col-lg-12 col-md-12 ">
            
            <button type="button" class="btn btn-success btn-lg" onClick={routeChange} style={{ "position": "absolute", "top": "86px", "right": "50px", "z-index": "10000", "width": "160px" ,"font": "inherit"}}> +Add Content</button>

            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Content Data</h5>


                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Page Name</th>
                      <th scope="col">Type</th>
                      <th scope="col">Heading</th>                     
                      <th scope="col">Text Data</th>
                      <th scope="col">Image</th>
                      <th scope="col">Date</th>
                      <th scope="col">Update</th>
                      <th scope="col">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {content && content.map((content_data, i) => (

                      <tr key={content_data._id}>
                        <th scope="row">{i + 1}</th>
                        <td>{content_data.pageName}</td>
                        <td>{content_data.nameType}</td>
                        <td>{content_data.heading}</td>
                        <td>{content_data.textData}</td>
                        <td>{content_data.img}</td>
                        <td>{content_data.dateCreate}</td>
                        <td><Link class="btn btn-primary w-100" to={`../UpdateContent/${content_data._id}`} >Update</Link></td>
                        <td><button class="btn btn-primary w-100" onClick={() => { deleteData(content_data._id) }} type="submit">Delete</button></td>
                      </tr>
                    ))}
                  </tbody>
                </table>

              </div>
            </div>

          </div>


        </div>
      </section>
      <ToastContainer />
    </main>
  );

}

export default Content;