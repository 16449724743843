import React from "react";
import Uploadimg from "./uploadimg";
import { useState, useEffect, useReducer } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const FeeAdmin=()=>{
    const [feedata, setfee] = useState();
    const [comUpdate, forceUpdate] = useReducer((x) => x + 1, 0);
    const [data, setData] = useState({
      Month:'',
      Fee:'',
      Class:'',
      Year:'',
      SelClass:''
    });
  
   const [selectValue, setSelectValue] = useState({
      Class: "Preschool" ,//Default Value   
      Month:"Jan"
  });
  

    useEffect(() => {
      
      const url = 'http://localhost:5000/api/Feestr';
      showFee(url);
    }, [comUpdate]);



    
    const handleChange=(e)=>{
      const {name,value}= e.target
      setData ({
        ...data,
        [name]:value
      })
    }
  
    const handleSelectChange = (event) => {
      const { name, value } = event.target
  
      setSelectValue({
          ...selectValue,
          [name]: value
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault()
   const currYear=new Date().getFullYear();
    data.Year = currYear.toString() +"-"+ (currYear+1).toString();
    callApi("http://localhost:5000/api/Feestr/add");  
  };

  const callApi = async (url) => {

    const logData = {
      Class: selectValue.Class,
      Month: selectValue.Month,
      Year:data.Year,
      Amount: data.Fee        
    };

    try {
      const data = await fetch(url, {
        method: "post",
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify(logData)
      });
    
     const restatus = await data.json();
    

      if (restatus.status === 200) {
        toast.success("User Registration Successfull", {
            position: "top-right",
            theme: "colored",
        });
    }
    else {
        toast.error("User Registration Failed", {
            position: "top-right",
            theme: "colored",
        });
    }
     
    }
    catch (e) {
      console.log(e);
    }
   // forceUpdate();
   FetchFee(selectValue.SelClass);
  }

    const showFee = async (url) => {      
      const res = await fetch(url);
      const feedata = await res.json();          
      setfee(feedata.Obj_data);     
    }

    const FetchFee = (Feeclass)=>{
      let url="";
      if(Feeclass == "All")
       url = 'http://localhost:5000/api/Feestr';
    else
     url = 'http://localhost:5000/api/Feestr?Class='+Feeclass;
      showFee(url);
    }
  
    const deleteData = async (id) => {  
      const url = "http://localhost:5000/api/Feestr/delete/" + id;  
      const res = await fetch(url, {
        method: "delete"
      })
  
      const data = await res;
      if (data.status == "200") {
        toast.success("Data Deleted Successfull", {
          position: "top-right",
          theme: "colored",
        });
      }
      else {
        toast.error("Process Failed", {
          position: "top-right",
          theme: "colored",
        });
      }
     // forceUpdate()
     FetchFee(selectValue.SelClass);
    }

    return (
        <main id="main" className="main">
    
    
          <div className="pagetitle">
            <h1>Fee Admin Panal</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                <li className="breadcrumb-item">Fee Admin Panal Table</li>
    
              </ol>
            </nav>
          </div>
    
    
          <section className="section">
            < div className="row col-lg-12 col-md-12 ">
              <div className="row col-lg-7 col-md-7 ">
    
    
                <div className="card">
                  <div className="card-body">

                  < div className="row col-lg-12 col-md-12 ">
                  <div className="col-6">
                                 <div class="form-floating mb-3">                             
                                   

                                        <select className="form-select" id="SelClass" aria-label="State" name="SelClass" value={selectValue.SelClass} onChange={handleSelectChange}>
                                            <option value="All">All Classes</option>
                                            <option value="Preschool">Preschool</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                           

                                        </select>

                                        <label for="Class" className="form-label">Class</label>
                                  </div>
                                  </div>

                                  <div className="col-4"  style={{ "margin-top": "10px" }}>
                                  <div class="text-center">
                          <button className="btn btn-primary w-100" type="submit"  onClick={() => { FetchFee(selectValue.SelClass) }}>Fetch Fee Via Class</button>
                        </div>
                        </div>
                        </div>
                                  <hr></hr>


                    <h5 className="card-title">Fee Admin Panal</h5>
    
    
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Season Year</th>
                          <th scope="col">Class</th>
                          <th scope="col">Month</th>    
                          <th scope="col">Fee</th>
    
                          <th scope="col">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
    
                           feedata && feedata.map((fee, i) => (
                            <tr key={fee._id}>
                              <th scope="row">{i + 1}</th>
                              <td>{fee.Year}</td>
                              <td>{fee.Class}</td>
                              <td>{fee.Month}</td>
                              <td>{fee.Amount}</td>
                              <td><button class="btn btn-primary w-100" onClick={() => { deleteData(fee._id) }} type="submit">Delete</button></td>
                            </tr>
                          ))}
    
                      </tbody>
                    </table>
    
                  </div>
                </div>
              </div>
    
               <div className="row col-lg-4 col-md-4 " style={{ "margin-left": "50px" }}>

               <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Fee Add Panal</h5>
                    <hr></hr>


                                 <div className="col-12">
                                 <div class="form-floating mb-3">                             
                                   

                                        <select className="form-select" id="Class" aria-label="State" name="Class" value={selectValue.Class} onChange={handleSelectChange}>
                                            <option selected="" value="Preschool">Preschool</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                           

                                        </select>

                                        <label for="Class" className="form-label">Class</label>
                                  </div>
                                  </div>

                                  <div className="col-12">
                                  <div class="form-floating mb-3">   
                                  
                                   

                                        <select className="form-select" id="Month" aria-label="State" name="Month" value={selectValue.Month} onChange={handleSelectChange}>
                                           
                                            <option selected="" value="Jan">Jan</option>
                                            <option value="Feb">Feb</option>
                                            <option value="Mar">Mar</option>
                                            <option value="Apr">Apr</option>
                                            <option value="May">May</option>
                                            <option value="jun">jun</option>
                                            <option value="July">July</option>
                                            <option value="Aug">Aug</option>
                                            <option value="Sept">Sept</option>
                                            <option value="Oct">Oct</option>
                                            <option value="Nov">Nov</option>
                                            <option value="Dec">Dec</option>                                          

                                        </select>
                                        <label for="Month" className="form-label">Month</label>

                                  </div>
                                  </div>

                                  <div className="col-12">
                                  <div class="form-floating">
                                   
                                    <input type="text" name="Fee" className="form-control" value={data.Fee} onChange={handleChange} required/>
                                    <label for="ClassTeacher" className="form-label">Fee Amount</label>
                                    <div className="invalid-feedback">Please enter Fee Amount</div>
                                  </div>
                                  </div>

                                  <div className="col-12"  style={{ "margin-top": "50px" }}>
                                  <div class="text-center">
                          <button className="btn btn-primary w-100" type="submit"  on onClick={handleSubmit}>Submit Fee</button>
                        </div>
                        </div>


              </div> 
            </div>
            </div> 
            </div>
            
          </section>
          <ToastContainer />
        </main>
      );
    
}

export default FeeAdmin;