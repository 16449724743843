import React, { useState } from "react";
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Uploadimg = ({ onUpdate }) => {
    const [file, setFile] = useState();
    const [uploadfile, setuploadfile] = useState();


    const handleChange = (e) => {
        setFile(URL.createObjectURL(e.target.files[0]));
        setuploadfile(e.target.files[0]);
    }


    const handleSubmit = async (e) => {
        e.preventDefault()


        const formData = new FormData();
        formData.append('image', uploadfile);


        let url = window.location.href;
        let result = url.includes("Slider");
        if (url.includes("Slider"))
            url = "http://localhost:5000/api/img/upload";

        else if (url.includes("Content"))
            url = "http://localhost:5000/api/img/upload/content";
        else
            url = "http://localhost:5000/api/img/upload/gallary";

        try {
            const res = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Access-Control-Allow-Origin': '*'
                },
            });

            // Assuming the response contains the saved image path
            const imagePath = res.data.imagePath;

            console.log(imagePath);

            // Save the image path in MongoDB using an API call
            // ...


            toast.success("Pic Uploded Successfull", {
                position: "top-right",
                theme: "colored",
            });

            onUpdate(imagePath);

        } catch (error) {
            console.log(error);
            toast.error("Pic Uploding Process Failed", {
                position: "top-right",
                theme: "colored",
            });
        }
    };

    return (
        <section className="section">
            <div className="row">
                <div className="card">
                    <h3 className="card-title">Add Image</h3>
                    <img src={file} class="card-img-top" alt="..." />
                    <div className="card-body">


                        <hr></hr>
                        <input type="file" name="image" onChange={handleChange} />

                        <hr />

                        <button class="btn btn-primary w-100" type="submit" onClick={handleSubmit}>Upload Image</button>


                    </div>
                </div>

            </div>
            <ToastContainer />
        </section>
    );
}

export default Uploadimg;
